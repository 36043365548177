<template>
  <section class="cont">
    <!-- 面包屑 -->
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>业务中台</el-breadcrumb-item>
        <el-breadcrumb-item>对账报表</el-breadcrumb-item>
        <el-breadcrumb-item>交易账单</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <!-- 主体内容 -->
    <el-row class="content-box">
      <el-row class="search-box">
      <el-row class="search-row">
        <el-row class="search-item m-right-50">
          <label>订单编号：</label>
          <el-input class="width-180" placeholder="请输入订单编号" v-model="paramData" clearable></el-input>
        </el-row>

        <el-row class="search-item m-right-50">
          <label>支付时间：</label>
          <el-date-picker class="width-140" v-model="beginTime" type="date"
                          :picker-options="pickerOptions"
                          placeholder="请选择" value-format="yyyy-MM-dd"></el-date-picker>
          <span> 至 </span>
          <el-date-picker class="width-140" v-model="endTime" type="date"
                          :picker-options="pickerOptions"
                          placeholder="请选择" value-format="yyyy-MM-dd"></el-date-picker>
        </el-row>
        <el-button class="bg-gradient" @click="getList">搜索</el-button>
        <el-button class="bg-gradient" @click="resetGetList">重置</el-button>
        <el-button class="bg-gradient" type="primary"  @click="exportEvent">导出</el-button>
      </el-row>
        <el-row class="promptText-box">
          <el-alert class="m-bottom-20  width-350" title="不支持当日账单查询，10:30后可查询昨日账单" show-icon type="warning" :closable="false" ></el-alert>
        </el-row>
      </el-row>

      <el-table
          :data="tableData"
          border fit
          v-loading="loading"
      style="width: 100%"
          :stripe="true"
          :header-cell-style="{background: '#f5f8f9'}">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column label="订单时间" min-width="100" prop="createTime"></el-table-column>
        <el-table-column label="支付时间" min-width="100" prop="payTime"></el-table-column>
        <el-table-column label="系统订单编号" width="120" prop="orderNo"></el-table-column>
        <el-table-column label="微信支付订单编号" width="150" prop="paymentNo"></el-table-column>
        <el-table-column label="订单金额" min-width="100" prop="productTotalPrice"></el-table-column>
        <el-table-column label="订单类型"  width="100" prop="orderType">
          <template slot-scope="scope">
            {{scope.row.orderType | filterOrderType(that)}}
          </template>
        </el-table-column>
        <el-table-column label="商品名称" min-width="120" prop="title"></el-table-column>
        <el-table-column label="减免金额" width="100" prop="discountAmount"></el-table-column>
        <el-table-column label="实付金额" min-width="100" prop="actualAmount"></el-table-column>
        <el-table-column label="到账金额" min-width="100" prop="profit"></el-table-column>
        <el-table-column label="销售奖励金额" width="70" prop="commissionAmount"></el-table-column>
        <el-table-column label="销售奖励状态" width="70" prop="subAccountState">
          <template slot-scope="scope">
            {{scope.row.subAccountState | filterOrderStatus(that)}}
          </template>
        </el-table-column>
        <el-table-column label="达易住抽佣金额" prop="platformCommission"></el-table-column>
        <el-table-column label="达易住抽佣费率" prop="platformRate"></el-table-column>
        <el-table-column label="达易住抽佣状态" prop="platformSubState">
          <template slot-scope="scope">
            {{scope.row.platformSubState | filterOrderStatus(that) }}
          </template>
        </el-table-column>
        <el-table-column label="微信支付手续费" prop="thirdPartyCommission"></el-table-column>
        <el-table-column label="微信支付费率"  width="70" prop="thirdPartyRate"></el-table-column>
      </el-table>
      <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
    </el-row>
  </section>
</template>

<script>
import { urlObj , business } from '@/api/interface'
import {reconciliation} from "@/api/interface/business";
import {mapState} from "vuex";
import {dateFactory, exportFile, getDict} from "@/common/js/common";
export default {
  data(){
    return{
      that:this,
      loading:true,             // 表格加载效果
      tableData: [],             // 表格数据
      state:'',                  //哪个页面进入
      paramData:'',                //订单编号
      beginTime:'',
      endTime:'',
      pickerOptions: {
        disabledDate(time) {
          //如果没有后面的-8.64e7就是不可以选择今天的
          return time.getTime() > Date.now() -1 * 24 * 3600 * 1000;
        },
      },
      total: 0,                  // 用户列表总条目数
      page: 1,                   // 当前页 默认第一页
      limit: 10,                 // 每页显示数
    }
  },
  computed: {
    ...mapState(['hotelInfo','dictData'])
  },
  watch: {
    hotelInfo: {
      handler(newVal, oldVal) {
        if (newVal.id !== oldVal.id && oldVal) {
          this.getList()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.endTime = dateFactory.getDistanceToday(-1,false)
    this.limit = sessionStorage.getItem('pageSize') * 1
    getDict(['sub-account','order-type'])
    this.getList()
  },

  methods:{
    //获取表格数据
    getList(){
      let url = reconciliation.getBill+'?limit='+this.limit+'&page='+this.page
      let  param = {
          beginTime :this.beginTime,
          endTime:this.endTime,
          paramData:this.paramData,
          hotelId:this.hotelInfo.id
        }
        if (JSON.parse(sessionStorage.getItem('userInfo')).accountType == 'STORE'){
          param.hotelId = this.storeId
          param.storeId = this.hotelInfo.storeId
        }
        this.$axios.post(url,param,'json').then(res => {
          if (res.success) {
            this.loading = false
            this.tableData = res.records
            this.total = res.total
          }
        })
    },

    //重置
    resetGetList(){
      this.beginTime = ''
      this.endTime = ''
      this.paramData = ''
      this.getList()
    },
    //导出事件
    exportEvent(){
      if (this.tableData.length == 0){
        this.$message.error('暂无数据')
      }else{
        let  param = {
          beginTime :this.beginTime,
          endTime:this.endTime,
          paramData:this.paramData,
          hotelId:this.hotelInfo.id
        }
        if (JSON.parse(sessionStorage.getItem('userInfo')).accountType == 'STORE'){
          param.hotelId = this.storeId
          param.storeId = this.hotelInfo.storeId
        }
        const url = reconciliation.getBillExcel
        this.$axios.get(url, param, {}, 'blob').then(res => {
          exportFile(res, '交易账单')
        })
      }

    },
    // 改变每页数
    pageChange(num) {
      this.limit = num
      this.getList()
    },
    // 改变当前页
    handlePaging(num) {
      this.page = num
      this.getList()
    },
  },
  filters: {
    filterOrderStatus(val,that){
      if (val && that.dictData['sub-account']) return that.dictData['sub-account'][val] || val
    },
    filterOrderType(val,that){
      if(val === 'E_BOOKING') return '客房'
      if (val && that.dictData['order-type']) return that.dictData['order-type'][val] || val
    }
  }
}
</script>

<style scoped>

</style>
